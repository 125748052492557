import React from "react";
import { Link } from "gatsby";
import Author from "./Author";
import Comments from "./Comments";
import Content from "./Content";
import Meta from "./Meta";
import Tags from "./Tags";
import styles from "./Post.module.scss";
import { Node } from "../../types";
import Sidebar from '../Sidebar/Sidebar';

type Props = {
  post: Node,
};

const youtube = ['vzftWl99m_8','a-oBphG1f8g','7Y70gvy1UVQ', 'PaH7XKIoPEQ','0Rl9iK6DBT8','cuRDNtaifuI',
'Y3R3jxQ0S2Q','2UZnsPqR_44','taekbl5HxQg','i4MuPnNnNv8','jp0BJugrkwI','tTKmowtFE1Y','XNGMxm4ec9M',
'JDSQzaa0Ass','mgIKEdZjeh4','qHxCi4ES88Y','NdkrRyINFGM','gDA1p7SCXaA',
'Wc7cB9ir4o0','PimFTi5pubw','KrkHx7g7zJs','MvWpLA1IVFo','KfhRf_kDh7k','30_lWig2c0s',
'6lSXlLtaeHQ','Z6stIrA9Yuo','y5MXtyD58Vk',
'1dO9syq43XA','DlxEAAUspZU','Gm7lEpOZF94','1T1wT3z_MHA',
'dEAhIWItAtA','C15760VEGjw','ZMQFEMmZGss','PVM1zPiYRBw',
'hms5QACEYis','K3Di_Rbp7TA','8lTOnBBQdTo','b7R-7CvuZb4',
'F5HJdbWgOmk','kU_OrVRUoUc','IzdaKDwpLvQ','ylQ0HAfPzok',
'TQqu1W7O3k8','Ln5l0mjgT2o','94WCdzAzvzc','WL5qCtRNaj8','uyBow1qb-XE',
't6cQoz-GDCY','y1RzHYRiYJw','H2Riz-mCfWo','utGmcBKuV1E','PPcw7uAXey4',
'PPcw7uAXey4','61JPR6i_B-g','Zn2JSVxtMWA','i_VTUfKrnZo','FG2acr56HU8',
'FG2acr56HU8','ZfD5xhKwbxM','VrGrFNCkHcc','Z42hF9NCW_I','M38V9Ts6wFQ',
'4pYcsd8ub8E','Pv_T4ib-MQ4','f_zlSlMeJPE','dF6rSKVSxo4','_ZT_DG0YnxA',
'_78AH_agmxI','-kwMdazPRsE','akl4Xs9merQ','RG-jgLKSz0U','nfJOl0e-5kg',
'QBk8L-hDXPo','c5t9vEkim6Y','O-zts5s_ygI','NfjDTYxYmDQ','bV23FZb1_VM',
'Ctg60Msy_Wk','8OQ9MdaMwZM','_Bf-FG4PQM4','auDzZ71La8c','GG1RVgK_4IE','MoCKCoCF5CY','LzsqbKJWsAM',
'l6W5Y4asgP4','R-3VHaUj6H0','l6ALK6jjYME','DVtDBtlb3E8','CibHxTm75Bk','cfztbsD5zG4']

const Post: React.FC<Props> = ({ post }) => {
  const { html } = post;
  const { tagSlugs, slug } = post.fields;
  const { tags, title, date, updated } = post.frontmatter;
  const { featured_image } = post.frontmatter;
  const random = Math.floor(Math.random() * youtube.length);
  const youtubeLink = 'https://www.youtube.com/embed/'+youtube[random]

  return (
    <div className={styles["post"]}>
      {/* <Link className={styles["post__home-button"]} to="/">
        All Articles
      </Link> */}
      <Sidebar slug={slug}/>
      <div className={styles["post__content"]}>
        <Content body={html} title={title} featuredImage={featured_image}/>
        {/* <h3>Where is the color and why codevscolor ?</h3> */}
      {/* <p>Long story short, I love paintings and I paint on weekends. We(me and my wife) have one Youtube channel. Below is a video that I did recently. */}
        {/* If you love this please do subscribe to support us 😊</p> */}
        {/* <iframe width="560" height="315" src={youtubeLink} frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> */}
  
      </div>

      <div className={styles["post__footer"]}>
      {tags && tagSlugs && <Tags tags={tags} tagSlugs={tagSlugs} />}
        <Meta date={updated} />
        <Author />
      </div>
      <div className={styles["post__comments"]}>
        <Comments postSlug={slug} postTitle={post.frontmatter.title} />
      </div>
    </div>
  );
};

export default Post;
