import React from 'react';
import { getContactHref } from '../../../utils';
import styles from './Author.module.scss';
import { useSiteMetadata } from '../../../hooks';

const Author = () => {
  const { author } = useSiteMetadata();

  return (
    <div className={styles['author']}>
      <p className={styles['author__bio']}>
        {author.bio}
        <a
          className={styles['author__bio-twitter']}
          href={getContactHref('twitter', author.contacts.twitter)}
          rel="noopener noreferrer"
          target="_blank"
        >
          <strong>{author.name}</strong> on Twitter
        </a>
        <a href="https://codevscolor.com/pages/about" target="_blank" rel="noopener noreferrer">
          About
        </a>
        <br/>
        <a href="https://codevscolor.com/pages/privacy-policy" target="_blank" rel="noopener noreferrer">
          Privacy Policy
        </a>
        <br/>
        <a href="https://codevscolor.com/pages/terms-of-services" target="_blank" rel="noopener noreferrer">
          T&C
        </a>
        <br/>
        <a href="https://codevscolor.com/pages/contact" target="_blank" rel="noopener noreferrer">
          Contact
        </a>
      </p>
    </div>
  );
};

export default Author;
