import React from "react";
import styles from "./Content.module.scss";
import Img from "gatsby-image";

type Props = {
  body: string,
  title: string,
};

const Content: React.FC<Props> = ({ body, title, featuredImage }) => (
  <div className={styles["content"]}>
    <h1 className={styles["content__title"]}>{title}</h1>
    {featuredImage &&
      featuredImage.childImageSharp &&
      featuredImage.childImageSharp.fluid && (
        <Img fluid={featuredImage.childImageSharp.fluid} className={styles["content__featuredimage"]}/>
      )}
    <div
      className={styles["content__body"]}
      dangerouslySetInnerHTML={{ __html: body }}
    />
  </div>
);

export default Content;
