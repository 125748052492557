import React from "react";
import moment from "moment";
import styles from "./Meta.module.scss";

type Props = {
  date: string;
};

const Meta: React.FC<Props> = ({ date }) => (
  <div className={styles["meta"]}>
    {date && <p className={styles['meta__date']}>Updated on {moment(date).format('D MMM YYYY')}</p>}
  </div>
);

export default Meta;
